/*Border rotate*/
@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

.spinning-circle {
  background-clip: content-box;
  animation: spin 10s linear infinite;
}

/*Scrollbar*/
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height:10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #d55959;
}